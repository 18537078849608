<script lang="ts">
  import ContextAwareness from "./features/ContextAwareness.svelte";
  import ProTrainedAI from "./features/ProTrainedAI.svelte";
  import ZeroToHero from "./features/ZeroToHero.svelte";
  import HighQualityLowPrice from "./features/HighQualityLowPrice.svelte";
  import LearnsFromMistakes from "./features/LearnsFromMistakes.svelte";
  import ctaImg from "./features/assets/cta-img.webp";
  import multiplayerImg from "./features/assets/multiplayer-messages.webp";
  import light from "./features/assets/c1ll.webp";
  import squarebg from "$lib/assets/squarebg.webp";
  import light2 from "./features/assets/ctall.webp";
</script>

<section class="px-5 md-px-8">
  <div class="max-w-6xl mx-a pb-20 md-pt-20">
    <h1
      class="font-(semibold tasa) text-(center 3xl radial-4) md-text-5xl lg-text-6xl"
    >
      Meet Your New Coach
    </h1>

    <!-- FEATRUES LIST -->
    <div class="py-14">
      <div class="flex flex-col md-grid grid-cols-2 gap-6 mb-6">
        <ContextAwareness />
        <ProTrainedAI />
      </div>
      <div class="flex flex-col md-flex-row flex-wrap gap-6">
        <ZeroToHero />
        <HighQualityLowPrice />
        <LearnsFromMistakes />
      </div>
    </div>

    <!-- CTA -->
    <div
      class="cta relative w-full lg-grid grid-cols-2 overflow-hidden mb-14 md:my-30"
    >
      <div class="abs top-0 s-full flex-s-center">
        <div class="h-full">
          <img class="w-full" src={squarebg} alt="" />
        </div>
        <!-- RADIAL DARKENING GRADIENT -->
        <div
          class="abs top-0 left-0 s-full"
          style="background: radial-gradient(50% 50% at 50% 50%, rgba(10, 3, 0, 0.00) 0%, #0A0300 100%);"
        />
      </div>

      <!-- LEFT -->
      <div class="relative z-1 p-6 flex-s-center md-(p-15 pr-6)">
        <div>
          <h1
            class="capitalize font-(tasa semibold) text-(3xl radial-4) md-text-5xl lg-text-6xl"
          >
            Featuring guides from radiant professional, Sero
          </h1>
          <p class="text-(md neutral-brown-60) mt">
            CoachCamel is made in collaboration with Sero.
          </p>

          <a
            target="_blank"
            href="https://download.overwolf.com/install/Download?Name=CoachCamel&ExtensionId=pnefddejdcadgnbjnmlhijbmnhgjobkcjgpgaaei&Channel=web_dl_btn"
            class="main-btn-cta my-6 inline-flex-s-start gap-2 py-3 px-4 mx-a btn"
            download
          >
            <div class="i-bi-windows text-xs" />
            <span class="text-(md radial-3) font-(general medium)"
              >Download for Windows</span
            >
          </a>
          <!-- <a
            target="_blank"
            href="https://discord.gg/29YH7hTQXk"
            class="main-btn-cta my-6 inline-flex-s-start gap-4 py-3 px-4 mx-a btn"
          >
            <div class="i-bi-windows text-sm" />
            <p
              class="text-(md radial-3) font-(general medium) flex flex-col items-center"
            >
              <span>Join for Updates</span>

              <span class="text-xs">Undergoing Maintenance</span>
            </p>
          </a> -->
        </div>
      </div>

      <!-- RIGHT -->
      <div class="relative z-1 flex flex-col">
        <div class="lt-lg-max-w-80% md-h-300px lg-h-90% mt-a ml-a">
          <img class="object-(cover left)" src={ctaImg} alt="" />
        </div>
      </div>

      <!-- LIGHT BLOB -->
      <div
        class="abs top-0 lt-lg-(rotate-y-180deg left-0) lg-right-0 pointer-events-none"
      >
        <img src={light} alt="" />
      </div>
      <div class="abs w-500px top-0 left-0 pointer-events-none">
        <img class="w-full" src={light2} alt="" />
      </div>
    </div>

    <!-- Multiplayer -->
    <div
      class="cta relative w-full lg-grid grid-cols-2 overflow-hidden md:my-30"
    >
      <div class="abs top-0 s-full flex-s-center">
        <div class="h-full">
          <img class="w-full" src={squarebg} alt="" />
        </div>
        <!-- RADIAL DARKENING GRADIENT -->
        <div
          class="abs top-0 left-0 s-full"
          style="background: radial-gradient(50% 50% at 50% 50%, rgba(10, 3, 0, 0.00) 0%, #0A0300 100%);"
        />
      </div>

      <!-- LEFT -->
      <div class="relative z-1 p-6 flex-s-center md-(p-15 pr-6)">
        <div class="flex flex-col items-center">
          <h1
            class="capitalize font-(tasa semibold) text-(3xl radial-4) md-text-[3.5rem] md-leading-tight"
          >
            Rank Up Together!
          </h1>
          <p class="text-(md neutral-brown-60) mt">
            Multiplayer is available today!
          </p>

          <a
            target="_blank"
            href="https://download.overwolf.com/install/Download?Name=CoachCamel&ExtensionId=pnefddejdcadgnbjnmlhijbmnhgjobkcjgpgaaei&Channel=web_dl_btn"
            class="main-btn-cta my-6 inline-flex-s-start gap-2 py-3 px-4 mx-a btn"
            download
          >
            <div class="i-bi-windows text-xs" />
            <span class="text-(md radial-3) font-(general medium)"
              >Download for Windows</span
            >
          </a>
          <!-- <a
            target="_blank"
            href="https://discord.gg/29YH7hTQXk"
            class="main-btn-cta my-6 inline-flex-s-start gap-4 py-3 px-4 mx-a btn"
          >
            <div class="i-bi-windows text-sm" />
            <p
              class="text-(md radial-3) font-(general medium) flex flex-col items-center"
            >
              <span>Join for Updates</span>

              <span class="text-xs">Undergoing Maintenance</span>
            </p>
          </a> -->
        </div>
      </div>

      <!-- RIGHT -->
      <div class="relative z-1 flex flex-col justify-center items-center">
        <div
          class="lt-lg-max-w-80% md-h-250px lg-max-h-250px md-ml-auto md-mr-auto md-mb-unset mb-[3rem] relative"
        >
          <img
            class="object-(cover left) max-h-250px"
            src={multiplayerImg}
            alt="Multiplayer messages"
          />
          <div class="bg-#100D03/[0.35] abs w-full h-full" />
        </div>
      </div>

      <!-- LIGHT BLOB -->
      <div
        class="abs top-0 lt-lg-(rotate-y-180deg left-0) lg-right-0 pointer-events-none"
      >
        <img src={light} alt="" />
      </div>
      <div class="abs w-500px top-0 left-0 pointer-events-none">
        <img class="w-full" src={light2} alt="" />
      </div>
    </div>
  </div>
</section>

<style>
  .cta {
    border-radius: 20px;
    border: 1px solid var(--neutral-brown-v-700, #251f1c);
    background: var(--neutral-brown-v-900, #0a0300);
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(10, 3, 0, 0) 0%,
      #0a0300 100%
    );
  }
</style>
