<script lang="ts">
  import FeatureCard from "./FeatureCard.svelte";
  import StatusPill from "./StatusPill.svelte";
  import light from "./assets/c2ll.webp";
  import light2 from "./assets/c2rl.webp";
  import { animate, stagger, type AnimationControls } from "motion";
  import { onMount } from "svelte";
  let anim: AnimationControls;
  onMount(() => {
    if (anim) return;
    anim = animate(
      "svg.twinkle path",
      { fillOpacity: [1, 0, 1] },
      {
        duration: 2,
        easing: "ease-in-out",
        delay: stagger(0.2),
        repeat: Infinity,
      }
    );
  });
</script>

<FeatureCard
  title="AI model trained on millions of pro strategies"
  description="Our AI model is up to date on the latest patch of Valorant and produces intricate strategies for a variety of situations."
>
  <div class="group">
    <div
      class="abs top-0 left-0 s-full overflow-hidden px-3 sm:px-6 md:px-3 lg:px-6 py"
    >
      <!-- FIRST CALLOUT -->
      <div class="flex-s-between px py-3 rd-xl callout">
        <div class="font-(lexend semibold) text-(xl radial)">CoachCamel</div>

        <div class="relative">
          <p class="colored text-xs">Always Up-To-Date</p>

          <!-- TWINKLING SVG WRAPPER -->
          <div class="abs top-0 left-0 w-full h-full flex-s-center">
            <svg
              class="twinkle"
              xmlns="http://www.w3.org/2000/svg"
              width="124"
              height="51"
              viewBox="0 0 124 51"
              fill="none"
            >
              <mask
                id="mask0_35_889"
                style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="-5"
                y="6"
                width="135"
                height="40"
              >
                <rect
                  x="7"
                  y="18"
                  width="111"
                  height="16"
                  rx="8"
                  stroke="url(#paint0_angular_35_889)"
                  stroke-width="24"
                />
              </mask>
              <g mask="url(#mask0_35_889)">
                <path
                  d="M27.289 39.2413C27.692 38.3433 26.8262 37.4752 25.9305 37.8793C25.7365 37.9691 25.5872 38.1188 25.4976 38.3133C25.0797 39.2114 25.9604 40.0795 26.856 39.6754C27.0501 39.5856 27.1994 39.4359 27.289 39.2413Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M15.4374 36.464C15.8404 35.5659 14.9746 34.6978 14.079 35.1019C13.8849 35.1917 13.7356 35.3414 13.6461 35.536C13.2281 36.4341 14.1088 37.3022 15.0045 36.8981C15.1985 36.8083 15.3478 36.6586 15.4374 36.464Z"
                  fill="white"
                />
                <path
                  d="M33.8981 25.464C34.3022 24.5659 33.4341 23.6978 32.536 24.1019C32.3414 24.1917 32.1917 24.3414 32.1019 24.536C31.6978 25.4341 32.5659 26.3022 33.464 25.8981C33.6586 25.8083 33.8083 25.6586 33.8981 25.464Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M51.8983 36.4225C52.3014 35.5244 51.4356 34.6563 50.5399 35.0604C50.3458 35.1502 50.1966 35.2999 50.107 35.4945C49.689 36.3926 50.5697 37.2607 51.4654 36.8566C51.6595 36.7667 51.8088 36.6171 51.8983 36.4225Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M42.6461 46.464C42.2281 45.5659 43.1088 44.6978 44.0045 45.1019C44.1986 45.1917 44.3478 45.3414 44.4374 45.536C44.8404 46.4341 43.9746 47.3022 43.0789 46.8981C42.8849 46.8083 42.7356 46.6586 42.6461 46.464Z"
                  fill="white"
                />
                <path
                  d="M44.6461 10.464C44.2281 9.56594 45.1088 8.69782 46.0045 9.10194C46.1986 9.19175 46.3478 9.34142 46.4374 9.536C46.8404 10.4341 45.9746 11.3022 45.0789 10.8981C44.8849 10.8083 44.7356 10.6586 44.6461 10.464Z"
                  fill="white"
                />
                <path
                  d="M19.6461 14.464C19.2281 13.5659 20.1088 12.6978 21.0045 13.1019C21.1985 13.1917 21.3478 13.3414 21.4374 13.536C21.8404 14.4341 20.9746 15.3022 20.079 14.8981C19.8849 14.8083 19.7356 14.6586 19.6461 14.464Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M1.89833 21.4225C2.30138 20.5244 1.43556 19.6563 0.539885 20.0604C0.345822 20.1502 0.196576 20.2999 0.107009 20.4945C-0.310972 21.3926 0.569733 22.2607 1.46541 21.8566C1.65947 21.7667 1.80876 21.6171 1.89833 21.4225Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M5.89833 41.4225C6.30138 40.5244 5.43556 39.6563 4.53988 40.0604C4.34582 40.1502 4.19658 40.2999 4.10701 40.4945C3.68903 41.3926 4.56973 42.2607 5.46541 41.8566C5.65947 41.7667 5.80876 41.6171 5.89833 41.4225Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M6.64074 9.44056C6.23769 8.5425 7.10348 7.67438 7.99914 8.07851C8.1932 8.16831 8.34249 8.31799 8.43206 8.51257C8.85004 9.41063 7.96929 10.2787 7.07363 9.87462C6.87957 9.78481 6.7303 9.63513 6.64074 9.44056Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M11.641 24.464C11.2369 23.5659 12.105 22.6978 13.003 23.1019C13.1976 23.1917 13.3473 23.3414 13.4371 23.536C13.8412 24.4341 12.9731 25.3022 12.0751 24.8981C11.8805 24.8083 11.7308 24.6586 11.641 24.464Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M89.6407 25.4406C89.2377 24.5425 90.1035 23.6744 90.9991 24.0785C91.1932 24.1683 91.3425 24.318 91.4321 24.5126C91.85 25.4106 90.9693 26.2787 90.0736 25.8746C89.8796 25.7848 89.7303 25.6351 89.6407 25.4406Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M109.641 33.464C109.237 32.5659 110.105 31.6978 111.003 32.1019C111.198 32.1917 111.347 32.3414 111.437 32.536C111.841 33.4341 110.973 34.3022 110.075 33.8981C109.881 33.8083 109.731 33.6586 109.641 33.464Z"
                  fill="white"
                />
                <path
                  d="M94.3124 36.9103C94.7154 36.0122 93.8496 35.1441 92.954 35.5482C92.7599 35.638 92.6106 35.7877 92.5211 35.9823C92.1031 36.8804 92.9838 37.7485 93.8795 37.3443C94.0735 37.2545 94.2228 37.1049 94.3124 36.9103Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M82.4374 13.464C82.8404 12.5659 81.9746 11.6978 81.0789 12.1019C80.8849 12.1918 80.7356 12.3414 80.6461 12.536C80.2281 13.4341 81.1088 14.3022 82.0045 13.8981C82.1986 13.8083 82.3478 13.6586 82.4374 13.464Z"
                  fill="white"
                />
                <path
                  d="M104.641 14.464C104.237 13.5659 105.105 12.6978 106.003 13.1019C106.198 13.1917 106.347 13.3414 106.437 13.536C106.841 14.4341 105.973 15.3022 105.075 14.8981C104.88 14.8083 104.731 14.6586 104.641 14.464Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M109.898 23.4225C110.301 22.5244 109.436 21.6563 108.54 22.0604C108.346 22.1502 108.197 22.2999 108.107 22.4945C107.689 23.3926 108.57 24.2607 109.465 23.8566C109.659 23.7667 109.809 23.6171 109.898 23.4225Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M120.641 13.4406C120.238 12.5425 121.103 11.6744 121.999 12.0785C122.193 12.1683 122.342 12.318 122.432 12.5126C122.85 13.4106 121.969 14.2787 121.074 13.8746C120.88 13.7848 120.73 13.6351 120.641 13.4406Z"
                  fill="white"
                  fill-opacity="0.4"
                />
                <path
                  d="M121.641 34.464C121.237 33.5659 122.105 32.6978 123.003 33.1019C123.198 33.1917 123.347 33.3414 123.437 33.536C123.841 34.4341 122.973 35.3022 122.075 34.8981C121.88 34.8083 121.731 34.6586 121.641 34.464Z"
                  fill="white"
                  fill-opacity="0.4"
                />
              </g>
              <defs>
                <radialGradient
                  id="paint0_angular_35_889"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(62.5 26) scale(74.8044 10.7826)"
                >
                  <stop offset="0.32379" stop-color="white" stop-opacity="0" />
                  <stop offset="0.697471" stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" stop-opacity="0.5" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <!-- SECOND CALLOUT -->
      <!-- lg:group-hover-translate-x-45px group-hover-translate-x-15px group-hover:w-[285px] md:group-hover:w-80% -->
      <StatusPill
        class="mt sm-mt-8 w-80% group-hover-translate-x-12% group-hover-border-red-500 duration-550 transition-all duration-550"
        version="7.06"
        _new
      />

      <div class="ml-25px flex-s-start">
        <div class="lt-sm-w-10% group-hover-translate-x-70% transition-550">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-80px md:w-50px lg:w-83px h-151px"
            fill="none"
          >
            <path
              d="M1 0V27C1 32.5228 5.47715 37 11 37H83"
              stroke="#231D1A"
              stroke-width="1.5"
              class="group-hover-stroke-red-500 transition-all duration-550"
            />
            <path
              d="M1 27V85C1 90.5228 5.47715 95 11 95H83"
              stroke="#231D1A"
              stroke-width="1.5"
              class="group-hover-stroke-red-500 transition-all duration-550"
            />
            <path
              d="M1 82V151C1 156.523 5.47715 161 11 161H83"
              stroke="#231D1A"
              stroke-width="1.5"
              class="group-hover-stroke-red-500 transition-all duration-550"
            />
          </svg>
        </div>

        <div class="flex-1 relative z-2 self-stretch">
          <!-- lg:group-hover:w-[340px] -->
          <StatusPill
            class="mt-3 transition-all w-full duration-550 ease-in-out group-hover:w-85% group-hover-translate-x-5px"
            version="7.05"
          />
          <StatusPill
            class="mt-3 transition-all w-full duration-550 ease-in-out group-hover:w-85% group-hover-translate-x-5px"
            version="7.04"
          />
        </div>
      </div>

      <!-- DARKENING GRADIENT BACKGROUND -->
      <div
        class="h-70% abs z-2 bottom-0 left-0 w-full pointer-events-none"
        style="background: linear-gradient(180deg, rgba(10, 3, 0, 0.00) 8.33%, #0A0300 100%);"
      />
    </div>

    <!-- LIGHT -->
    <div
      class="abs top-0 right-0 pointer-events-none"
      style="transform: translateY(-10px); width: 377px;"
    >
      <img class="w-full" src={light} alt="" />
    </div>

    <!-- LIGHT -->
    <div
      class="abs top-0 left-0 pointer-events-none"
      style="transform: translateY(-100px); width: 350px;"
    >
      <img class="w-full" src={light2} alt="" />
    </div>
  </div>
</FeatureCard>

<style>
  .callout {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #0a0300;
    box-shadow: 0px -5px 19px 0px rgba(64, 64, 64, 0.25) inset;
  }

  .colored {
    background: linear-gradient(180deg, #ffbea3 0%, #f06f38 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>
