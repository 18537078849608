<script lang="ts">
  import cn from "clsx";
  import riot from "$lib/assets/riot.svg";
  import { createAccordion, melt } from "@melt-ui/svelte";
  import { slide } from "svelte/transition";

  const {
    elements: { content, item, trigger, root },
    helpers: { isSelected },
  } = createAccordion();

  const items = [
    {
      id: "item-1",
      title: "Is CoachCamel compliant with Riot Games?",
      desc: "CoachCamel is fully compliant with Riot Games.",
    },
    {
      id: "item-2",
      title: "Is CoachCamel safe to use?",
      desc: "CoachCamel is 100% safe to use and is supported by Overwolf.",
    },
    {
      id: "item-3",
      title: "Is CoachCamel collecting data from my PC?",
      desc: "CoachCamel does not and will never collect data from your PC.",
    },
    {
      id: "item-4",
      title: "How many video guides are on CoachCamel?",
      desc: "CoachCamel currently hosts 10 guides from Radiant Professional, Sero. New guides are added every week! Be on the lookout for new Sero content on CoachCamel!",
    },
    {
      id: "item-5",
      title: "Does CoachCamel slow down my PC?",
      desc: "CoachCamel is an ultra-light application. Your FPS and input delay remain unchanged while using CoachCamel.",
    },
    {
      id: "item-6",
      title: "What other features does CoachCamel have?",
      desc: "CoachCamel provides advice in real-time, generates notes with AI based on your match, and hosts an array of guides from Sero.",
    },
  ];
</script>

<section class="px-5 md-px-8" id="faq">
  <div
    class="max-w-6xl mx-a lg-grid pb-20"
    style:grid-template-columns="0.9fr 1fr"
  >
    <!-- LEFT SIDE -->
    <div class="mb-10 pr-10">
      <!-- CALLOUT -->
      <div
        class="callout rd-full b py-2 px-3.5 text-xs mx-a mb inline-flex-s-start gap-2"
      >
        <div class="h-15px">
          <img class="h-full" src={riot} alt="" />
        </div>
        <span class="text-radial fw-medium">Frequently Asked Questions</span>
      </div>

      <!-- TITLE -->
      <h1
        class="capitalize font-(tasa semibold) text-(3xl radial-4) md-text-5xl lg-text-6xl"
      >
        Have any questions?
      </h1>
    </div>

    <!-- RIGHT SIDE -->
    <div class="flex flex-col gap-4" {...$root}>
      {#each items as { id, title, desc }}
        <div
          class="det rd-xl bg-neutral-brown-800 overflow-hidden"
          use:melt={$item(id)}
        >
          <h2>
            <button
              class="w-full p-4 flex-s-between gap-3 text-start md-p-6"
              use:melt={$trigger(id)}
            >
              <span class="text-radial fw-semibold md-text-lg">{title}</span>

              <div
                style="transition: background 0.5s ease"
                class={cn(
                  "s-24px rd-full flex-s-center",
                  $isSelected(id) ? "grad" : "bg-neutral-brown-600"
                )}
              >
                <div
                  class={cn(
                    "text-radial-4 i-mdi-plus transition-250",
                    $isSelected(id) && "rotate-45"
                  )}
                  style="background: radial-gradient(50% 50% at 50% 50%, #FFFFFF, #BCBCBC);"
                />
              </div>
            </button>
          </h2>

          {#if $isSelected(id)}
            <div
              class="b-t b-neutral-brown-600 text-neutral-brown-200"
              use:melt={$content(id)}
              transition:slide={{ duration: 250 }}
            >
              <div class="px-5 py-4 fw-medium">{desc}</div>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
</section>

<style>
  .det {
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .grad {
    background: linear-gradient(180deg, #ff833e, #ff6107);
  }
</style>
