<script lang="ts">
  import logo from "$lib/assets/finalCtaLogo1.webp";
</script>

<section class="px mt--5 md-px-8">
  <div class="pb-0 max-w-6xl mx-a flex-s-center|col">
    <div class="relative h-300px flex items-center md-h-500px">
      <div class="image-bg" />

      <img
        class="relative z-2 h-30% lg:h-37% aspect-square object-contain"
        src={logo}
        alt="CoachCamel Logo"
      />
    </div>

    <div class="relative z-2 top--14 md-top--28 flex-s-center|col">
      <p
        class="pb-1 capitalize font-(tasa semibold) text-(3xl radial-4 center) md-text-5xl lg-text-6xl"
      >
        You've been playing the hard way, <br /> Start playing the easy way.
      </p>

      <a
        target="_blank"
        href="https://download.overwolf.com/install/Download?Name=CoachCamel&ExtensionId=pnefddejdcadgnbjnmlhijbmnhgjobkcjgpgaaei&Channel=web_dl_btn"
        class="main-btn-cta mt-8 inline-flex-s-start gap-2 py-3 px-4 mx-a btn"
        download
      >
        <div class="i-bi-windows text-xs" />
        <span class="text-(md radial-3) font-(general medium)"
          >Download for Windows</span
        >
      </a>
      <!-- <a
        target="_blank"
        href="https://discord.gg/29YH7hTQXk"
        class="main-btn-cta mt-8 inline-flex-s-start gap-4 py-3 px-4 mx-a btn"
      >
        <div class="i-bi-windows text-sm" />
        <span
          class="text-(md radial-3) font-(general medium) flex flex-col items-center"
        >
          <span>Join for Updates</span>

          <span class="text-xs">Undergoing Maintenance</span></span
        >
      </a> -->
    </div>
  </div>
</section>

<style>
  .image-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    /* background-image: linear-gradient(180deg, #ffab90 0%, #ff7a00 100%); */
    filter: blur(100px);
    transform: translate(-50%, -50%);
  }

  @media (min-width: 1024px) {
    .image-bg {
      width: 222px;
      height: 222px;
    }
  }

  @media (min-width: 640px) {
    .image-bg {
      width: 222px;
      height: 222px;
      background-image: linear-gradient(180deg, #ffab90 0%, #ff7a00 100%);
    }
  }

  /* .rad::after {
    @apply content-empty block abs z-1 top-0 left-0 s-full;
    background: rgb(255, 255, 255);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 0%,
      rgba(10, 3, 0, 0.4) 100%
    );
  } */
</style>
