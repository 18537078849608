<script lang="ts">
  export let _new = false;
  export let version: string;
  let _class = "";
  export { _class as class };
</script>

<div
  class="rd-xl bg-#0B0401 lg:px px-8px lg:py-3 py-2 flex-s-between border border-#ffffff1a {_class}"
>
  <div class="flex-s-start gap-2 text-white">
    <!-- ICON WRAPPER -->
    <div class="s-22px flex-s-center rd-full" style:background="#FF4848">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
      >
        <path
          d="M0.410609 0.510983C0.479861 0.470774 0.523989 0.552042 0.561346 0.594804C2.96506 3.52323 5.37161 6.44954 7.7751 9.37797C7.82447 9.41967 7.78712 9.50881 7.72049 9.49923C6.56964 9.50051 5.41858 9.49966 4.26773 9.49966C4.17554 9.50072 4.08729 9.45605 4.0307 9.38605C2.8366 7.93322 1.64316 6.47975 0.449058 5.0267C0.398375 4.96798 0.37216 4.89203 0.375437 4.81566C0.375437 3.41197 0.375874 2.00807 0.375 0.604378C0.375655 0.571189 0.374345 0.528215 0.410609 0.510983Z"
          fill="white"
        />
        <path
          d="M11.5277 0.504126C11.5769 0.486043 11.6311 0.530294 11.6238 0.580501C11.6256 1.98483 11.6236 3.38916 11.6247 4.79348C11.628 4.87305 11.6101 4.95538 11.557 5.01793C11.1941 5.46001 10.8308 5.90188 10.468 6.34375C10.4075 6.42034 10.3085 6.46268 10.2095 6.45779C9.06634 6.45672 7.92292 6.45842 6.77972 6.45693C6.71222 6.46715 6.67377 6.37843 6.72358 6.33567C8.30894 4.40416 9.89561 2.47329 11.4818 0.542207C11.4939 0.525613 11.5092 0.512849 11.5277 0.504126Z"
          fill="white"
        />
      </svg>
    </div>

    <span class="text-xs lg:text-sm flex-1">Valorant {version}</span>
  </div>
  <span class="text-(xs white)">{_new ? "New" : "Old"} Version</span>
</div>

<style>
  .callout {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #0a0300;
    box-shadow: 0px -5px 19px 0px rgba(64, 64, 64, 0.25) inset;
  }

  .colored {
    background: linear-gradient(180deg, #ffbea3 0%, #f06f38 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>
