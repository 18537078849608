<script lang="ts">
  import FeatureCard from "./FeatureCard.svelte";
  import light from "./assets/c2rl.webp";
  import realcamel from "$lib/assets/logo-msg.webp";
  import razeonbind from "$lib/assets/features/razeonbind.webp";
</script>

<FeatureCard
  title="Learn from your mistakes"
  description="CoachCamel summarizes and organizes your notes after your match is over automatically."
>
  <div class="abs top-0 left-0 s-full px-6 py overflow-hidden group">
    <!-- SCROLL ANIMATION WRAPPER -->
    <div class="group-hover-translate-y--220px transition-750 ease-out">
      <div class="message px-5 py-3">
        <div class="flex-s-start gap-4 mb-2">
          <div class="s-22px rd-full overflow-hidden">
            <img class="w-full h-full" src={realcamel} alt="" />
          </div>
          <div class="font-(lexend semibold) text-(lg #E6E9EE)">CoachCamel</div>
        </div>

        <p
          class="text-neutral-brown-100 lh-150%"
          style="font-size: 12.421px;letter-spacing: -0.137px;"
        >
          Please take a moment to comment your thoughts on the last match. I
          will summarize this message and add them to your notes.
        </p>
      </div>

      <div class="px py-14.51px mt-46px flex-s-start gap-14px listening">
        <div>
          <!-- prettier-ignore -->
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="#251f1c"><rect y="5.499" width="2.388" height="8.359" rx="1.194"/><rect x="4.776" y=".722" width="2.388" height="17.913" rx="1.194"/><rect x="9.553" y="5.499" width="2.388" height="8.359" rx="1.194"/><rect x="14.33" y="4.304" width="2.388" height="10.748" rx="1.194"/></svg>
        </div>
        <p class="text-(xs white) fw-medium">Listening...</p>
      </div>

      <div class="mt-6">
        <div class="flex-s-start gap-2 mb-2.5">
          <div class="s-22px rd-full">
            <img class="w-full" src={razeonbind} alt="" />
          </div>
          <p class="text-(xl radial) font-(tasa semibold)">Raze on Bind</p>
        </div>

        <ul
          class="text-(12.421px/150% neutral-brown-100) flex flex-col gap-2.5
          list-disc pl-6 [&>li]-max-w-303px"
        >
          <li class="listening px-2 py-1">
            Combo Raze's grenade with <span class="text-red-500">Viper's</span>
            bite to delay defuse on the
            <span class="text-red-500">A site.</span> Bounce it off the wall from
            behind RV.
          </li>

          <li class="listening px-2 py-1">
            Push through the teleporter with Raze's grenade to catch the enemy
            team offguard while pushing B.
          </li>

          <li class="listening px-2 py-1">
            Don't play top truck on A site, it's hard to fall back as Raze
          </li>
        </ul>
      </div>
    </div>

    <!-- DARKENING GRADIENT BACKGROUND -->
    <div
      class="h-76px abs z-2 bottom-0 left-0 w-full pointer-events-none"
      style="background: linear-gradient(180deg, rgba(10, 3, 0, 0.00) 0%, #0A0300 100%);"
    />
  </div>

  <!-- LIGHT -->
  <div
    class="abs top-0 left-0 pointer-events-none"
    style="transform: translateY(-100px); width: 370px;"
  >
    <img class="w-full" src={light} alt="" />
  </div>
</FeatureCard>

<style>
  .message {
    border-radius: 12.376px;
    border: 2.484px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.04);
  }

  .listening {
    border-radius: 9.553px;
    border: 0.597px solid var(--neutral-brown-v-800, #16100d);
    background: #0a0300;
    box-shadow: 0px -4.7767px 38.21359px 0px rgba(126, 126, 126, 0.12) inset;
  }
</style>
