<script lang="ts">
  import FeatureCard from "./FeatureCard.svelte";
  import coords from "$lib/assets/features/coords.webp";
  import light from "./assets/c1ll.webp";
  import realcamel from "$lib/assets/logo-msg.webp";
  import { onMount } from "svelte";

  import bg1 from "$lib/assets/features/cc1bg.webp";
  import bg2 from "$lib/assets/features/cc1bg2.webp";
  import bg3 from "$lib/assets/features/cc1bg3.webp";

  onMount(() => {});
</script>

<FeatureCard
  title="Real-time context awareness"
  description="Ask questions without telling CoachCamel what's happening in your match. CoachCamel already knows."
>
  <div class="abs top-0 left-0 s-full p-6 flex-s-between">
    <div
      class="flex flex-col gap-4 self-stretch w-[calc(100%/2)] max-w-159px relative z-2"
    >
      <!-- CARD 1 | BIND SECTION -->
      <div class="card">
        <div
          class=" p-2 bg-(cover center no-repeat)"
          style:background-image="url({bg1})"
        >
          <!-- prettier-ignore -->
          <svg xmlns="http://www.w3.org/2000/svg" width="73" height="24" viewBox="0 0 73 24" fill="none">
        <path d="M16.425 16.5543C16.425 20.7899 13.3211 23.2149 8.5035 23.2149H0V0H8.05085C12.7391 0 15.552 2.16629 15.552 6.07855C15.552 8.27718 14.2264 10.1525 11.9954 11.1225C14.873 11.9308 16.425 13.9031 16.425 16.5543ZM7.82452 2.84528H3.2656V9.82915H7.82452C10.6375 9.82915 12.1571 8.5035 12.1571 6.33721C12.1571 4.17092 10.6375 2.84528 7.82452 2.84528ZM8.27717 12.6744H3.2656V20.3696H8.27717C11.3165 20.3696 13.0301 18.9146 13.0301 16.4574C13.0301 14.0971 11.3165 12.6744 8.27717 12.6744Z" fill="url(#paint0_radial_542_2086)"/>
        <path d="M24.0803 23.2149H20.5237V0H24.0803V23.2149Z" fill="url(#paint1_radial_542_2086)"/>
        <path d="M44.6318 23.2149L32.41 5.07624V23.2149H29.1121V0H32.992L44.9228 17.6537V0H48.2207V23.2149H44.6318Z" fill="url(#paint2_radial_542_2086)"/>
        <path d="M60.5748 23.2149H53.2353V0H60.5748C67.979 0 72.2469 4.72058 72.2469 11.5104C72.2469 18.2033 67.8174 23.2149 60.5748 23.2149ZM56.7919 3.07161V20.1433H60.4132C65.7481 20.1433 68.6257 16.8453 68.6257 11.5104C68.6257 6.27255 65.7804 3.07161 60.4132 3.07161H56.7919Z" fill="url(#paint3_radial_542_2086)"/>
        <defs>
          <radialGradient id="paint0_radial_542_2086" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.6422 0.120099) rotate(89.2838) scale(23.0966 40.1179)">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#8A8380"/>
          </radialGradient>
          <radialGradient id="paint1_radial_542_2086" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.6422 0.120099) rotate(89.2838) scale(23.0966 40.1179)">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#8A8380"/>
          </radialGradient>
          <radialGradient id="paint2_radial_542_2086" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.6422 0.120099) rotate(89.2838) scale(23.0966 40.1179)">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#8A8380"/>
          </radialGradient>
          <radialGradient id="paint3_radial_542_2086" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.6422 0.120099) rotate(89.2838) scale(23.0966 40.1179)">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#8A8380"/>
          </radialGradient>
        </defs>
      </svg>

          <div style="height: 6.93px; margin-top: 10.39px;">
            <img class="h-full" src={coords} alt="" />
          </div>

          <div
            class="fw-light text-neutral-brown-500"
            style="margin-top: 9.56px;font-size: 6.313px;line-height: normal;letter-spacing: 0.6px;"
          >
            STANDART - CUSTOM
          </div>
        </div>
      </div>

      <div class="card relative p-1">
        <div
          class="s-full p-2 bg-(cover center no-repeat)"
          style:background-image="url({bg2})"
        />

        <div
          class="abs top-0 left-0 s-full"
          style="border-radius: 8px;
border: 0.5px solid #251F1C;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(97, 97, 97, 0.20) 100%), radial-gradient(50% 50% at 50% 50%, rgba(14, 5, 1, 0.00) 0%, #0E0501 100%);
box-shadow: 0px 0px 48px 0.62821px rgba(66, 61, 59, 0.02), 0px 2.51282px 6.28205px 0px rgba(130, 130, 130, 0.25) inset;"
        />
      </div>

      <div class="card">
        <div
          class="s-full p-2 bg-(cover center no-repeat)"
          style:background-image="url({bg3})"
        />
      </div>
    </div>

    <div class="w-96px relative z-1 @max-sm-left--30px">
      <!-- prettier-ignore -->
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="182" viewBox="0 0 96 182" fill="none">
        <path d="M0 1H24.1678C33.0662 1 40.2797 6.37258 40.2797 13V91M0 91H40.2797M40.2797 91V169C40.2797 175.627 33.0662 181 24.1678 181H0M40.2797 91H69.1468M96 91L67.1329 91" stroke="#26201D"/>
      </svg>
    </div>

    <div class="flex-1 relative z-2 @max-sm-left--30px">
      <div class="message px py-3">
        <div class="flex-s-start gap-2 mb-2">
          <div class="s-18px rd-full overflow-hidden">
            <img class="w-full" src={realcamel} alt="" />
          </div>
          <div
            class="font-(lexend semibold) text-(sm #E6E9EE) relative @max-sm-top-2px"
          >
            CoachCamel
          </div>
        </div>

        <p
          class="text-neutral-brown-100 lh-150%"
          style="font-size: 10px;letter-spacing: -0.11px;"
        >
          I see that you're playing <span class="orange">Jett</span> on
          <span class="orange">Bind</span>. You can use your
          <span class="orange">dash early-round</span> to challenge the
          <span class="orange">enemy Raze</span>. This will condition her into
          playing more passive which denies information.
        </p>
      </div>
    </div>

    <!-- LIGHT -->
    <div class="abs top-0 right-0 pointer-events-none">
      <img src={light} alt="" />
    </div>
  </div></FeatureCard
>

<style>
  .card {
    @apply rd-lg overflow-hidden;
    height: 74px;
    border: 0.5px solid #251f1c;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(97, 97, 97, 0.2) 100%
      ),
      #0e0501;
    box-shadow: 0px 0px 48px 0.62821px rgba(66, 61, 59, 0.02),
      0px 2.51282px 6.28205px 0px rgba(130, 130, 130, 0.25) inset;
  }

  .message {
    border-radius: 9.963px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.04);
  }

  .orange {
    color: #ff8345;
  }
</style>
