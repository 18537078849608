<script lang="ts">
  let _class = "";
  export { _class as class };
  export let title = "";
  export let description = "";
</script>

<!-- CARD -->
<div class="card min-h-400px flex flex-(1 col) basis-300px {_class}">
  <!-- IMAGE -->
  <div class="relative flex-1 px-6 py">
    <slot />
  </div>

  <!-- DESCRIPTION -->
  <div class="px-6 pb-6 mt-a bg-neutral-brown-900">
    <p class="fw-medium mb-2 text-(lg white) capitalize">{title}</p>
    <p class="text-(sm neutral-brown-60)">{description}</p>
  </div>
</div>

<style>
  .card {
    @apply bg-neutral-brown-900 b b-neutral-brown-700
    rd-20px overflow-hidden;
    border-radius: 20px;
  }
</style>
