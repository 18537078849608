<script lang="ts">
  import heroImage from "$lib/assets/hero-image.webp";
  import emailIcon from "$lib/assets/streamline_ai-email-generator-spark.webp";
  import avatarIcon from "$lib/assets/vavatar.webp";
  import logoIcon from "$lib/assets/finalCtaLogo1.webp";
  import cn from "clsx";
  import { afterUpdate } from "svelte";
  import {
    animate,
    timeline,
    type AnimationControls,
    type TimelineDefinition,
  } from "motion";

  let showButton = true;
  let showBlur = false;

  $: firstAnim = false;
  let secondAnim = false;
  let thirdAnim = false;

  async function runAnimations() {
    showButton = false;
    showBlur = true;

    await wait(500);

    firstAnim = true;

    await wait(3000);

    firstAnim = false;
    secondAnim = true;

    await wait(1500);

    thirdAnim = true;

    await wait(2500);

    secondAnim = false;
    thirdAnim = false;
    showBlur = false;
    showButton = true;
  }

  function wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  let anim: AnimationControls;

  const audioAnimSequence: TimelineDefinition = [
    [".b1", { height: ["17px", "13px", "19px"] }],
    [".b2", { height: ["25px", "19px", "13px"] }, { at: "<" }],
    [".b3", { height: ["13px", "15px", "26px"] }, { at: "<" }],
    [".b4", { height: ["30px", "17px", "13px"] }, { at: "<" }],
    [".b5", { height: ["19px", "19px", "16px"] }, { at: "<" }],
    [".b6", { height: ["21px", "17px", "13px"] }, { at: "<" }],
    [".b7", { height: ["25px", "13px", "19px"] }, { at: "<" }],
  ];

  afterUpdate(() => {
    if (firstAnim) {
      animate(".bx", { backgroundColor: "#ffffff" }, { duration: 0.5 });
      anim = timeline(audioAnimSequence, {
        defaultOptions: { easing: "ease-out", direction: "alternate" },
        duration: 1,
        repeat: Infinity,
      });
    }
  });
</script>

<div class="aspect-video anim relative z-2 p-1px rd-20px overflow-hidden group">
  <div
    class={cn(
      "flex items-center justify-center abs inset-2px rd-20px group-hover-backdrop-blur-md transition-all duration-500",
      showBlur && "backdrop-blur-md"
    )}
  >
    <div
      on:click={runAnimations}
      class:hide_button_anim={showButton === false}
      class="flex cursor-pointer items-center py-8px px-9px md:py-16px md:px-18px box-style duration-500 transition-all group-hover:pointer-events-auto group-hover:opacity-100 opacity-0 pointer-events-none"
    >
      <div class="mr-10px w-20px h-20px">
        <img src={emailIcon} alt="Email Icon" class="w-full h-full" />
      </div>

      <p class="text-xs md:text-base flex-1">
        Generate Personalized Advice in Real-Time
      </p>
    </div>

    <div
      class:show={firstAnim === true}
      class="first_anim w-200px md:w-258px h-54px flex gap-10px abs bottom-10px left-20px md:bottom-30px md:left-40px"
    >
      <div
        class="md:w-54px w-35px h-35px md:h-54px toggle-size-anim relative overflow-hidden"
      >
        <img src={avatarIcon} alt="Avatar Icon" />

        <div class="flex-s-start gap-2px absolute bottom--3 left-0 right-0">
          <div class="bx b1" style:height="17px" />
          <div class="bx b2" style:height="25px" />
          <div class="bx b3" style:height="13px" />
          <div class="bx b4" style:height="30px" />
          <div class="bx b5" style:height="19px" />
          <div class="bx b6" style:height="21px" />
          <div class="bx b7" style:height="25px" />
        </div>
      </div>

      <div class="flex flex-col items-start gap-5px flex-1">
        <p class="text-white text-xs md:text-base">Jett</p>

        <p class="text-#ffffff8f text-xs md:text-base">Talking to CoachCamel</p>
      </div>
    </div>

    <div
      class:show={secondAnim === true}
      class="secondAnim md:py-12px py-6px px-8px md:px-16px abs top-15px md:top-45px left-5px md:left-auto right-5px md:right-40px md:w-299px flex flex-col items-start gap-4px md:gap-8px message-style"
    >
      <div class="flex items-center gap-8px">
        <div class="w-20px h-20px">
          <img src={avatarIcon} alt="Avatar Icon" />
        </div>

        <p class="text-white font-semibold text-sm md:text-base">Jett (You)</p>
      </div>

      <p class="text-#FEFAF8 text-xs">
        We can’t take the A site, what do we do?
      </p>
    </div>

    <div
      class:show={thirdAnim === true}
      class="thirdAnim md:py-12px py-6px px-8px md:px-16px abs top-80px md:top-145px left-5px md:left-auto right-5px md:right-40px md:w-299px flex flex-col items-start gap-4px md:gap-8px message-style"
    >
      <div class="flex items-center gap-8px">
        <div class="w-20px h-20px">
          <img src={logoIcon} alt="Logo Icon" />
        </div>

        <p class="text-white font-semibold text-sm md:text-base">CoachCamel</p>
      </div>

      <p class="text-#FEFAF8 text-xs">
        Since you’re on <b>Icebox</b>, buy an <b>operator</b> and look for an
        <b>early-pick from Nest</b>. Use your <b>dash</b> to move backsite.
      </p>
    </div>
  </div>

  <div class="w-full h-full">
    <img src={heroImage} class="w-full h-full" />
  </div>
</div>

<style>
  .thirdAnim {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, transform 1s, visibility 0s 0.3s;
    transform: translateY(-150px);
  }

  .thirdAnim.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, transform 1s;
    transform: translateY(0px);
  }

  .secondAnim {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, transform 1s, visibility 0s 0.3s;
    transform: translateY(-100px);
  }

  .secondAnim.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, transform 1s;
    transform: translateY(0px);
  }
  .first_anim {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s, visibility 0s 0.3s;
    transform: translateY(100px);
  }

  .first_anim.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(0px);
  }

  .hide_button_anim {
    visibility: hidden;
    transform: scale(0);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s, visibility 0s 0.3s;
  }

  .message-style {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #0a0300;
    box-shadow: 0px -4px 32px 0px rgba(126, 126, 126, 0.12) inset,
      0px -5px 100.7px 0px rgba(92, 92, 92, 0.25) inset;
  }

  .bx {
    @apply w-5px rd-full;
  }

  @keyframes scale {
    0%,
    100% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
  }

  .toggle-size-anim {
    background-color: lightblue;
    animation: scale 2s infinite;
  }

  .box-style {
    border-radius: 55px;
    border: 1px solid var(--orange-normal, #f06f38);
    background: linear-gradient(
        180deg,
        rgba(100, 100, 100, 0.12) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      rgba(255, 255, 255, 0.04);
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.08),
      0px 2px 6.4px 0px rgba(254, 96, 7, 0.24);
    backdrop-filter: blur(10.050000190734863px);
  }

  .anim {
    /* angular gradient border */
    background: linear-gradient(
      var(--gradient-angle),
      #f06f3800,
      #f06f3800,
      #f06f38,
      #f06f3800,
      #f06f3800
    ) !important;
    animation: gradient-rotate 5s linear infinite reverse;
  }

  @property --gradient-angle {
    syntax: "<angle>"; /* <- defined as type angle for the transition to work */
    initial-value: 360deg;
    inherits: false;
  }

  @keyframes gradient-rotate {
    0% {
      --gradient-angle: 360deg;
    }

    100% {
      --gradient-angle: 0deg;
    }
  }
</style>
