<script lang="ts">
  import FeatureCard from "./FeatureCard.svelte";
  import avatar from "$lib/assets/vavatar.webp";
  import camel from "$lib/assets/features/log-vsg.webp";
  import light from "./assets/c1ll.webp";
  import {
    animate,
    timeline,
    type AnimationControls,
    type TimelineDefinition,
  } from "motion";

  let anim: AnimationControls;

  const audioAnimSequence: TimelineDefinition = [
    [".b1", { height: ["7px", "3px", "9px"] }],
    [".b2", { height: ["15px", "9px", "3px"] }, { at: "<" }],
    [".b3", { height: ["3px", "5px", "16px"] }, { at: "<" }],
    [".b4", { height: ["20px", "7px", "3px"] }, { at: "<" }],
    [".b5", { height: ["9px", "9px", "6px"] }, { at: "<" }],
    [".b6", { height: ["11px", "7px", "3px"] }, { at: "<" }],
    [".b7", { height: ["15px", "3px", "9px"] }, { at: "<" }],
    [".b8", { height: ["9px", "7px", "7px"] }, { at: "<" }],
    [".b9", { height: ["17px", "6px", "6px"] }, { at: "<" }],
    [".b10", { height: ["10px", "15px", "10px"] }, { at: "<" }],
    [".b11", { height: ["12px", "13px", "9px"] }, { at: "<" }],
    [".b12", { height: ["17px", "9px", "9px"] }, { at: "<" }],
    [".b13", { height: ["11px", "13px", "4px"] }, { at: "<" }],
    [".b14", { height: ["10px", "12px", "4px"] }, { at: "<" }],
    [".b15", { height: ["8px", "10px", "15px"] }, { at: "<" }],
  ];

  function handleMouseOver() {
    if (anim) return;

    // timeline(
    //   [
    //     [".anim-wrapper", { y: "-50px" }],
    //     [".bx", { backgroundColor: "#F06F38" }],
    //   ],
    //   { duration: 0.5 }
    // );

    animate(".bx", { backgroundColor: "#F06F38" }, { duration: 0.5 });
    anim = timeline(audioAnimSequence, {
      defaultOptions: { easing: "ease-out", direction: "alternate" },
      duration: 1,
      repeat: Infinity,
    });
  }

  function handleMouseOut() {
    animate(".bx", { backgroundColor: "#251f1c" }, { duration: 0.5 });
    anim.cancel();
    anim = null!;
  }
</script>

<FeatureCard
  title="Access advice seamlessly"
  description="Ask a question using your voice and get clutch advice from CoachCamel on screen."
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="abs top-0 left-0 s-full px-6 py-9 overflow-hidden group"
    on:mouseenter={handleMouseOver}
    on:mouseleave={handleMouseOut}
  >
    <!-- SCROLL ANIMATION WRAPPER -->
    <div
      class="flex flex-col gap-6 anim-wrapper transition-450 group-hover-translate-y--25%"
    >
      <!-- CHAT BUBBLE WRAPPER -->
      <div class="flex-s-start-start gap-2">
        <div class="s-32px">
          <img src={avatar} alt="" />
        </div>
        <div class="">
          <div class="text-(xs white) fw-medium mb-2">Sero</div>
          <div
            class="chat-bubble text-xs p-2"
            style="border-radius: 2px 8px 8px 8px;"
          >
            <p class="mb-6px max-w-220px">
              We keep losing rounds and forcing a buy. What do we do?
            </p>
            <div class="text-(6px/normal neutral-brown-500 right)">
              Voice Message
            </div>
          </div>
        </div>
      </div>

      <!-- CHAT BUBBLE WRAPPER -->
      <div class="flex-s-start-start gap-2 ml-a">
        <div class="s-32px order-2">
          <img src={camel} width="34" height="35" alt="" />
        </div>
        <div class="">
          <div class="text-(xs white right) fw-medium mb-2">CoachCamel</div>
          <div
            class="chat-bubble text-xs p-2"
            style="border-radius: 8px 2px 8px 8px;"
          >
            <p class="mb-6px max-w-220px">
              Save and push as a team to secure frags to get weapons from the
              enemy.
            </p>
            <div class="text-(6px/normal neutral-brown-500 right)">
              Voice Message
            </div>
          </div>
        </div>
      </div>

      <!-- CHAT BUBBLE WRAPPER -->
      <div class="flex-s-start gap-2">
        <div class="s-32px">
          <img src={avatar} alt="" />
        </div>
        <div class="">
          <div
            class="chat-bubble flex-s-start gap-2.5 rd-full"
            style:padding="8px 10px 6.5px 8px"
          >
            <div class="i-icon-park-solid-voice text-orange-normal" />

            <div class="flex-s-start gap-2px">
              <div class="bx b1" style:height="7px" />
              <div class="bx b2" style:height="15px" />
              <div class="bx b3" style:height="3px" />
              <div class="bx b4" style:height="20px" />
              <div class="bx b5" style:height="9px" />
              <div class="bx b6" style:height="11px" />
              <div class="bx b7" style:height="15px" />
              <div class="bx b8" style:height="9px" />
              <div class="bx b9" style:height="17px" />
              <div class="bx b10" style:height="10px" />
              <div class="bx b11" style:height="12px" />
              <div class="bx b12" style:height="17px" />
              <div class="bx b13" style:height="11px" />
              <div class="bx b14" style:height="10px" />
              <div class="bx b15" style:height="8px" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DARKENING GRADIENT BACKGROUND -->
    <div
      class="h-76px abs z-2 bottom-0 left-0 w-full pointer-events-none"
      style="background: linear-gradient(180deg, rgba(10, 3, 0, 0.00) 0%, #0A0300 100%);"
    />
  </div>

  <!-- LIGHT -->
  <div class="abs top-0 right-0 pointer-events-none">
    <img src={light} alt="" />
  </div>
</FeatureCard>

<style>
  .chat-bubble {
    border: 0.5px solid var(--neutral-brown-v-800, #16100d);
    background: #0a0300;
    box-shadow: 0px -4px 32px 0px rgba(126, 126, 126, 0.12) inset;
  }

  .bx {
    @apply w-2px rd-full bg-neutral-brown-700;
  }
</style>
