<script lang="ts">
  import logoname from "$lib/assets/logo/logo-name.webp";
  import footertxt from "$lib/assets/footertxt.webp";
</script>

<footer class="px-5 md-px-8 relative overflow-hidden">
  <div class="b-t b-neutral-brown-700 w-full lg-max-w-90% mx-a" />

  <div class="max-w-6xl mx-a py-20 md-pb-35 md-flex-s-between">
    <div class="lt-md-mb-6">
      <a class="" href="/">
        <div class="h-30px">
          <img class="h-full" src={logoname} alt="" />
        </div>
      </a>

      <p class="mt-3">Your AI Gaming Coach!</p>

      <div class="flex-s-start gap-6 mt-8 text-2xl">
        <a target="_blank" href="https://discord.gg/HfzRK88RQP">
          <div class="i-ic-round-discord" />
        </a>
        <a target="_blank" href="https://twitter.com/heycoachcamel">
          <div class="i-mdi-twitter" />
        </a>
      </div>
    </div>

    <div class="flex flex-col gap-3">
      <a target="_blank" href="https://discord.gg/HfzRK88RQP">Discord</a>
      <a href="mailto:david@coachcamel.com">Email</a>
      <a href="/">Pricing</a>
      <a href="/">Privacy Policy</a>
    </div>
  </div>

  <div class="abs top-120% left-0 w-full z-10 pointer-events-none">
    <div class="relative w-full max-w-3xl mx-a">
      <div class="rad h-350px" />
    </div>
  </div>

  <div class="abs z-10 bottom-0 left-0 w-full pointer-events-none">
    <div class="max-w-100rem mx-a">
      <img class="w-full" src={footertxt} alt="" />
    </div>
  </div>
</footer>

<style>
  .rad::after {
    @apply content-empty block abs z-1 top-0 left-0 s-full;
    border-radius: 434px;
    background: var(--orange-normal, #f06f38);
    filter: blur(203.5px);
  }
</style>
