<script lang="ts">
  import riot from "$lib/assets/riot.svg";
  import squarebg from "$lib/assets/squarebg.webp";
  import HeroAnimation from "./HeroAnimation.svelte";
</script>

<section class="px-5 relative overflow-hidden">
  <div class="max-w-6xl mx-a py-20">
    <!-- MAIN CONTENT -->
    <div class="flex flex-col gap-6 text-center relative z-2">
      <!-- CALLOUT -->
      <div
        class="callout scale-120 rd-full b py-2 px-3.5 text-xs mx-a inline-flex-s-start gap-2"
      >
        <div class="h-15px">
          <img class="h-full" src={riot} alt="" />
        </div>
        <span class="text-radial fw-medium">Approved by Riot Games</span>
      </div>

      <h1
        class="font-(semibold tasa) text-(3xl radial-2) md-text-5xl lg-text-6xl"
        style:padding-bottom="2px"
      >
        Reach Your Dream Rank <span class="orange">Today</span>
      </h1>

      <p class="md-text-lg">
        Rank Up Faster with an AI Coach Equipped with Professional Strategies
      </p>

      <a
        target="_blank"
        href="https://download.overwolf.com/install/Download?Name=CoachCamel&ExtensionId=pnefddejdcadgnbjnmlhijbmnhgjobkcjgpgaaei&Channel=web_dl_btn"
        class="main-btn-cta inline-flex-s-start gap-2 py-3 px-4 mx-a btn"
        download
      >
        <div class="i-bi-windows text-xs" />
        <span class="text-(md radial-3) font-(general medium)"
          >Download for Windows</span
        >
      </a>
      <!-- <a
        target="_blank"
        href="https://discord.gg/29YH7hTQXk"
        class="main-btn-cta inline-flex-s-start gap-4 py-3 px-4 mx-a btn"
      >
        <div class="i-bi-windows text-sm" />
        <p
          class="text-(md radial-3) font-(general medium) flex flex-col items-center"
        >
          <span>Join for Updates</span>

          <span class="text-xs">Undergoing Maintenance</span>
        </p>
      </a> -->
    </div>

    <!-- VIDEO PREVIEW CARD -->
    <div class="mt-10 relative z-1">
      <!-- <HeroVideo /> -->
      <HeroAnimation />

      <!-- LIGHT BLOB -->
      <div class="abs top--20 sm-top--55 left-0 w-full z-1">
        <img fetchpriority="high" class="h-full" src={squarebg} alt="checkered background" />

        <!-- RADIAL DARKENING GRADIENT -->
        <div
          class="abs top-0 left-0 s-full"
          style="background: radial-gradient(circle, rgba(255,255,255,0) 0%, hsl(18 100% 2% / 0.4) 27%, hsl(18 100% 2% / 0.8) 51%);"
        />

        <!-- ACTUAL BLOB -->
        <div class="abs top-0 left-0 w-full z-1">
          <!-- prettier-ignore -->
          <svg
            class="s-full"
            width="1440"
            height="1134"
            viewBox="0 0 1440 1134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              style="mix-blend-mode:difference"
              filter="url(#filter0_f_1_1136)"
            >
              <path
                d="M853.081 304.89C988.304 381.161 1065.81 505.991 1035.29 619.881C996.114 766.087 793.757 838.899 583.314 782.51C419.385 738.586 298.905 629.22 272.171 513.912C334.384 465.589 425.452 417.249 535.719 377.573C645.986 337.896 757.242 313.436 853.081 304.89Z"
                fill="url(#paint0_radial_1_1136)"
                fill-opacity="0.6"
              />
              <path
                d="M853.081 304.89C988.304 381.161 1065.81 505.991 1035.29 619.881C996.114 766.087 793.757 838.899 583.314 782.51C419.385 738.586 298.905 629.22 272.171 513.912C334.384 465.589 425.452 417.249 535.719 377.573C645.986 337.896 757.242 313.436 853.081 304.89Z"
                fill="url(#paint1_radial_1_1136)"
                fill-opacity="0.5"
                style="mix-blend-mode:screen"
              />
              <path
                d="M853.081 304.89C988.304 381.161 1065.81 505.991 1035.29 619.881C996.114 766.087 793.757 838.899 583.314 782.51C419.385 738.586 298.905 629.22 272.171 513.912C334.384 465.589 425.452 417.249 535.719 377.573C645.986 337.896 757.242 313.436 853.081 304.89Z"
                fill="url(#paint2_radial_1_1136)"
                fill-opacity="0.6"
                style="mix-blend-mode:screen"
              />
              <path
                d="M853.081 304.89C988.304 381.161 1065.81 505.991 1035.29 619.881C996.114 766.087 793.757 838.899 583.314 782.51C419.385 738.586 298.905 629.22 272.171 513.912C334.384 465.589 425.452 417.249 535.719 377.573C645.986 337.896 757.242 313.436 853.081 304.89Z"
                fill="url(#paint3_radial_1_1136)"
                fill-opacity="0.12"
                style="mix-blend-mode:color-dodge"
              />
              <path
                d="M851.518 312.121C984.258 387.751 1057.63 509.217 1028.47 618.054C1009.65 688.297 951.442 741.629 871.136 770.524C790.861 799.409 689.147 803.558 585.142 775.69C424.914 732.757 308.092 627.069 280.116 516.708C341.485 469.883 430.454 422.954 538.11 384.217C647.02 345.029 756.829 320.785 851.518 312.121Z"
                stroke="url(#paint4_linear_1_1136)"
                stroke-opacity="0.32"
                stroke-width="14.123"
              />
            </g>
            <g
              style="mix-blend-mode:difference"
              filter="url(#filter1_f_1_1136)"
            >
              <path
                d="M745.216 305.225C880.439 381.497 957.941 506.327 927.425 620.217C888.249 766.422 685.893 839.234 475.45 782.846C311.521 738.921 191.04 629.556 164.307 514.248C226.519 465.924 317.587 417.584 427.855 377.908C538.122 338.232 649.377 313.772 745.216 305.225Z"
                fill="url(#paint5_radial_1_1136)"
                fill-opacity="0.6"
              />
              <path
                d="M745.216 305.225C880.439 381.497 957.941 506.327 927.425 620.217C888.249 766.422 685.893 839.234 475.45 782.846C311.521 738.921 191.04 629.556 164.307 514.248C226.519 465.924 317.587 417.584 427.855 377.908C538.122 338.232 649.377 313.772 745.216 305.225Z"
                fill="url(#paint6_radial_1_1136)"
                fill-opacity="0.5"
                style="mix-blend-mode:screen"
              />
              <path
                d="M745.216 305.225C880.439 381.497 957.941 506.327 927.425 620.217C888.249 766.422 685.893 839.234 475.45 782.846C311.521 738.921 191.04 629.556 164.307 514.248C226.519 465.924 317.587 417.584 427.855 377.908C538.122 338.232 649.377 313.772 745.216 305.225Z"
                fill="url(#paint7_radial_1_1136)"
                fill-opacity="0.6"
                style="mix-blend-mode:screen"
              />
              <path
                d="M745.216 305.225C880.439 381.497 957.941 506.327 927.425 620.217C888.249 766.422 685.893 839.234 475.45 782.846C311.521 738.921 191.04 629.556 164.307 514.248C226.519 465.924 317.587 417.584 427.855 377.908C538.122 338.232 649.377 313.772 745.216 305.225Z"
                fill="url(#paint8_radial_1_1136)"
                fill-opacity="0.12"
                style="mix-blend-mode:color-dodge"
              />
              <path
                d="M743.654 312.457C876.393 388.086 949.767 509.552 920.604 618.389C901.782 688.632 843.577 741.964 763.272 770.86C682.996 799.744 581.282 803.893 477.277 776.025C317.049 733.092 200.227 627.405 172.251 517.043C233.621 470.219 322.59 423.289 430.245 384.552C539.155 345.365 648.964 321.12 743.654 312.457Z"
                stroke="url(#paint9_linear_1_1136)"
                stroke-opacity="0.32"
                stroke-width="14.123"
              />
            </g>
            <g
              style="mix-blend-mode:difference"
              filter="url(#filter2_f_1_1136)"
            >
              <path
                d="M1105.22 333.225C1240.44 409.497 1317.94 534.327 1287.42 648.217C1248.25 794.422 1045.89 867.234 835.45 810.846C671.521 766.921 551.04 657.556 524.307 542.248C586.519 493.924 677.587 445.584 787.855 405.908C898.122 366.232 1009.38 341.772 1105.22 333.225Z"
                fill="url(#paint10_radial_1_1136)"
                fill-opacity="0.6"
              />
              <path
                d="M1105.22 333.225C1240.44 409.497 1317.94 534.327 1287.42 648.217C1248.25 794.422 1045.89 867.234 835.45 810.846C671.521 766.921 551.04 657.556 524.307 542.248C586.519 493.924 677.587 445.584 787.855 405.908C898.122 366.232 1009.38 341.772 1105.22 333.225Z"
                fill="url(#paint11_radial_1_1136)"
                fill-opacity="0.5"
                style="mix-blend-mode:screen"
              />
              <path
                d="M1105.22 333.225C1240.44 409.497 1317.94 534.327 1287.42 648.217C1248.25 794.422 1045.89 867.234 835.45 810.846C671.521 766.921 551.04 657.556 524.307 542.248C586.519 493.924 677.587 445.584 787.855 405.908C898.122 366.232 1009.38 341.772 1105.22 333.225Z"
                fill="url(#paint12_radial_1_1136)"
                fill-opacity="0.6"
                style="mix-blend-mode:screen"
              />
              <path
                d="M1105.22 333.225C1240.44 409.497 1317.94 534.327 1287.42 648.217C1248.25 794.422 1045.89 867.234 835.45 810.846C671.521 766.921 551.04 657.556 524.307 542.248C586.519 493.924 677.587 445.584 787.855 405.908C898.122 366.232 1009.38 341.772 1105.22 333.225Z"
                fill="url(#paint13_radial_1_1136)"
                fill-opacity="0.12"
                style="mix-blend-mode:color-dodge"
              />
              <path
                d="M1103.65 340.457C1236.39 416.086 1309.77 537.552 1280.6 646.389C1261.78 716.632 1203.58 769.964 1123.27 798.86C1043 827.744 941.282 831.893 837.277 804.025C677.049 761.092 560.227 655.405 532.251 545.043C593.621 498.219 682.59 451.289 790.245 412.552C899.155 373.365 1008.96 349.12 1103.65 340.457Z"
                stroke="url(#paint14_linear_1_1136)"
                stroke-opacity="0.32"
                stroke-width="14.123"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1_1136"
                x="-31.8285"
                y="0.889893"
                width="1377.73"
                height="1104.7"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="152"
                  result="effect1_foregroundBlur_1_1136"
                />
              </filter>
              <filter
                id="filter1_f_1_1136"
                x="-139.693"
                y="1.22534"
                width="1377.73"
                height="1104.7"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="152"
                  result="effect1_foregroundBlur_1_1136"
                />
              </filter>
              <filter
                id="filter2_f_1_1136"
                x="220.307"
                y="29.2253"
                width="1377.73"
                height="1104.7"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="152"
                  result="effect1_foregroundBlur_1_1136"
                />
              </filter>
              <radialGradient
                id="paint0_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(964.355 884.61) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.312609" stop-color="#341901" stop-opacity="0" />
                <stop offset="0.598958" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint1_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(964.355 884.61) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.282434" stop-color="#341901" stop-opacity="0" />
                <stop offset="0.598958" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint2_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(964.355 884.61) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.458176" stop-color="#612302" stop-opacity="0" />
                <stop offset="0.591097" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint3_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(964.355 884.61) rotate(-130.21) scale(425.169 450.831)"
              >
                <stop stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <linearGradient
                id="paint4_linear_1_1136"
                x1="964.355"
                y1="884.61"
                x2="837.691"
                y2="532.588"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF9051" />
                <stop offset="1" stop-color="#FF9051" stop-opacity="0" />
              </linearGradient>
              <radialGradient
                id="paint5_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(856.491 884.946) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.312609" stop-color="#341901" stop-opacity="0" />
                <stop offset="0.598958" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint6_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(856.491 884.946) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.282434" stop-color="#341901" stop-opacity="0" />
                <stop offset="0.598958" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint7_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(856.491 884.946) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.458176" stop-color="#612302" stop-opacity="0" />
                <stop offset="0.591097" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint8_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(856.491 884.946) rotate(-130.21) scale(425.169 450.831)"
              >
                <stop stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <linearGradient
                id="paint9_linear_1_1136"
                x1="856.491"
                y1="884.946"
                x2="729.827"
                y2="532.924"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF9051" />
                <stop offset="1" stop-color="#FF9051" stop-opacity="0" />
              </linearGradient>
              <radialGradient
                id="paint10_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1216.49 912.946) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.312609" stop-color="#341901" stop-opacity="0" />
                <stop offset="0.598958" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint11_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1216.49 912.946) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.282434" stop-color="#341901" stop-opacity="0" />
                <stop offset="0.598958" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint12_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1216.49 912.946) rotate(-130.21) scale(1100.25 1166.66)"
              >
                <stop offset="0.458176" stop-color="#612302" stop-opacity="0" />
                <stop offset="0.591097" stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint13_radial_1_1136"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1216.49 912.946) rotate(-130.21) scale(425.169 450.831)"
              >
                <stop stop-color="#FE6007" />
                <stop offset="1" stop-color="#341901" stop-opacity="0" />
              </radialGradient>
              <linearGradient
                id="paint14_linear_1_1136"
                x1="1216.49"
                y1="912.946"
                x2="1089.83"
                y2="560.924"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF9051" />
                <stop offset="1" stop-color="#FF9051" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="relative hidden">
  <!-- PATTERN -->
  <div class="w-full relative z-1">
    <!-- prettier-ignore -->
    <svg class="w-full" xmlns="http://www.w3.org/2000/svg" width="1440" height="411" viewBox="0 0 1440 411" fill="none" stroke="#251f1c"><path d="M-268.465-128.978l310.95 310.951c12.497 12.497 12.497 32.758 0 45.255l-310.95 310.95m65.953-666.862l310.951 310.951c12.497 12.496 12.497 32.758 0 45.254l-310.951 310.951m65.954-666.862l310.951 310.95c12.497 12.497 12.497 32.758 0 45.255l-310.951 310.951m65.954-666.863l310.951 310.951c12.496 12.497 12.496 32.758 0 45.255l-310.951 310.95M-4.65-127.803l310.951 310.95c12.496 12.497 12.496 32.759 0 45.255L-4.65 539.353m65.954-666.862l310.95 310.95c12.497 12.497 12.497 32.758 0 45.255L61.304 539.647m65.954-666.863l310.95 310.951c12.497 12.497 12.497 32.758 0 45.255l-310.95 310.95m65.953-666.862l310.951 310.951c12.497 12.496 12.497 32.758 0 45.254L193.211 540.234m65.954-666.862L592.743 206.95m0 0L259.165 540.528M592.743 206.95H847.5m862.66 332.053l-312.41-309.48c-12.56-12.437-12.65-32.698-.22-45.254l309.48-312.415m-62.8 667.166l-312.42-309.479c-12.55-12.438-12.65-32.699-.21-45.254l309.48-312.415m-62.81 667.166l-312.41-309.479c-12.56-12.438-12.65-32.699-.21-45.255l309.48-312.415m-62.81 667.167l-312.42-309.48c-12.55-12.437-12.65-32.698-.21-45.254l309.48-312.415m-62.8 667.166l-312.42-309.479c-12.56-12.438-12.65-32.699-.21-45.254l309.48-312.415m-62.81 667.166l-312.41-309.479c-12.56-12.438-12.66-32.699-.22-45.255l309.48-312.415m-62.8 667.167l-312.42-309.48c-12.554-12.437-12.65-32.698-.21-45.254l309.48-312.415m-62.81 667.166L936.067 229.647c-12.555-12.438-12.651-32.699-.213-45.254l309.476-312.415m-62.8 667.166l-335.151-332 332.001-335.149"/></svg>
  </div>

  <!-- DARKENED LINEAR GRADIENT -->
  <div
    class="abs top-0 left-0 s-full z-2"
    style="background: radial-gradient(50% 50% at 50% 50%, rgba(10, 3, 0, 0.00) 0%, #0A0300 100%);"
  />
</section>

<style>
  .orange {
    background: linear-gradient(
      90deg,
      #cf612a 0.36%,
      #ee8d33 49.53%,
      #ff8c8c 99.75%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>
